import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private apiUrl: string = environment.apiUrl;

  // BehaviorSubject to hold the total quantity of items in the cart
  private cartQuantitySubject = new BehaviorSubject<number>(parseInt(localStorage.getItem('cartQuantity') ?? '0', 10) || 0);
  cartQuantity$ = this.cartQuantitySubject.asObservable().pipe(shareReplay(1));
  
  constructor(
    private http: HttpClient,
  ) { }

  initializeCartData() {
    this.getCart().subscribe({
      next: () => console.log('Cart data initialized after login'),
      error: (error: any) => console.error('Error fetching cart data after login:', error)
    });
  }

  addToCart(packageId: number, quantity?: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/cart`, { packageId, quantity }).pipe(
      tap(() => this.updateCartQuantity())
    );
  }

  updateCartItem(item: any, newLocations?: any): Observable<any> {
    const data = { packageId: item.package.id, quantity: item.quantity, location: newLocations };
    return this.http.patch(`${this.apiUrl}/private/cart/${item.uid}`, data).pipe(
      tap(() => this.updateCartQuantity())
    );
  }

  getCart(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/cart`).pipe(
      tap((response: any) => {
        const totalQuantity = this.calculateTotalQuantity(response.data);
        this.cartQuantitySubject.next(totalQuantity);
        localStorage.setItem('cartQuantity', totalQuantity.toString());
      })
    );
  }

  removeCartItem(uid: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/cart/${uid}`).pipe(
      tap(() => this.updateCartQuantity())
    );
  }

  private updateCartQuantity() {
    this.getCart().subscribe({
      next: (response: any) => {
        this.cartQuantitySubject.next(this.calculateTotalQuantity(response.data))
      },
      error: (error: any) => console.error('Error updating cart quantity:', error)
    });
  }

  private calculateTotalQuantity(cartData: any[]): number {
    if (!cartData) {
      localStorage.setItem('cartQuantity', '0');
      return 0;
    }
    localStorage.setItem('cartQuantity', cartData.reduce((total, item) => total + (item.quantity || 0), 0).toString());
    return cartData.reduce((total, item) => total + (item.quantity || 0), 0);
  }
  

  getBundle(cartUidList?: string[], promotionCode?: string | null): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/cart/bundle`, { cartUidList, promotionCode }).pipe(
      tap((response: any) => this.cartQuantitySubject.next(this.calculateTotalQuantity(response.data.cartData)))
    );
  }
  
  // checkout(fullName: string, phoneNumber: string, cartUidList: string[], noLocationPref: boolean, promotionCode?: string): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/private/cart/checkout`, { fullName, phoneNumber, cartUidList, noLocationPref, promotionCode });
  // }

  checkout(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/cart/checkout`, data);

  }
}
